body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrolling */
}

.app {
  background-color: #222;
  color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding doesn't overflow */
}

header {
  text-align: center;
  margin-bottom: 10px;
}

header h1 {
  margin: 0;
  font-size: 2em;
}

header h2{
  margin: 5px 0 0 0;
  font-size: 1.2em;
  color: #aaa;
}

h5{
  margin: 5px 0 0 0;
  color: #fff;
}

input, textarea, select, button {
  font-size: 16px; /* Ensure font-size is 16px or larger */
}

footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  text-align: right;
}

/* Active Users Button in Footer */
.active-users-button-footer {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.active-users-button-footer:hover {
  color: #ccc;
}

/* Active Users Popup */
.active-users-popup {
  position: fixed;
  bottom: 60px; /* Adjust based on your footer height */
  right: 20px; /* Adjust based on desired position */
  background-color: #333;
  border: 1px solid #444;
  padding: 15px;
  z-index: 1000;
  width: 250px;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 4px;
}

.active-users-popup h3 {
  margin-top: 0;
  color: #fff;
  font-size: 1.2em;
  text-align: center;
}

.active-users-popup ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.active-users-popup li {
  padding: 5px 0;
  border-bottom: 1px solid #555;
  color: #fff;
}

/* Adjust the footer to position elements */
footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: -30px 0px -30px 0; /* Adjust padding as needed */
  margin: -30px 0px -20px 0;
}

/* Keep the GitHub link aligned to the left */
footer .github-link {
  margin-left: 10px;
}

/* Keep the active users button aligned to the right */
footer .active-users-button-footer {
  margin-right: 10px;
}

/* Center the disclaimer text */
.footer-disclaimer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px; /* Adjust as needed */
  text-align: center;
  color: #999; /* Adjust text color */
}

/* Style for the "Learn more" link */
.footer-disclaimer a {
  color: #007bff; /* Adjust link color */
  text-decoration: none;
}

.footer-disclaimer a:hover {
  text-decoration: underline;
}


.github-icon {
  width: 24px;
  height: 24px;
}

.github-link {
  text-decoration: none;
  color: inherit;
}

.messages-container {
  width: 100%;
  max-width: 1200px;
  height: calc(100vh - 250px);
  overflow-y: auto;
  margin-bottom: 20px;
  border: 1px solid #444;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
  background-color: #333;
  border-radius: 5px;
}

.messages {
  display: flex;
  flex-direction: column;
}

.message {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 4px;
  background-color: #444;
}

.own-message {
  align-self: flex-end;
  background-color: #007bff;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.settings-container {
  position: relative;
  margin-right: 5px;
}

.settings-button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.settings-popup {
  position: absolute;
  bottom: 40px;
  left: 0;
  background-color: #333;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 250px;
  z-index: 100;
}

.settings-popup h3 {
  margin-top: 0;
  color: #fff;
  font-size: 1em;
  text-align: center;
}

.settings-popup input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 14px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #555;
  color: #fff;
}

.settings-popup button {
  width: 48%;
  padding: 8px;
  margin-right: 4%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.settings-popup button:last-child {
  margin-right: 0;
  background-color: #555;
  color: #fff;
}

.settings-popup button:first-child {
  background-color: #007bff;
  color: #fff;
}

.settings-popup button:hover:first-child {
  background-color: #0056b3;
}

.settings-popup button:hover:last-child {
  background-color: #666;
}

form {
  display: flex;
  flex: 1;
}

input[type="text"], input[type="password"] {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  background-color: #444;
  color: #fff;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}

/* Media Queries */

@media only screen and (max-width: 768px) {
  .messages-container {
    height: calc(100vh - 300px);
  }
  .settings-popup {
    width: 220px;
  }
}

@media only screen and (max-width: 600px) {
  .messages-container {
    height: calc(100vh - 350px);
  }
}

@media only screen and (max-width: 480px) {
  .messages-container {
    height: calc(100vh - 450px);
  }
}
